<template>
  <v-footer
    id="dashboard-core-footer"
    style="width: 100%"
  >
   <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; 2022, Hyphen
          
          </div>
    
    
    
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
   
    }),
  }
</script>

<style >
#dashboard-core-footer{
  position: absolute;
  bottom: 0;
}

</style>
